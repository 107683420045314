:root {
  --body-background: #e4e9f7;
  --body--color: #000;
  --link--color: navy;
  --card-header-color-rgb: 33, 37, 41;
  --card-background: #fff;
  --card-border: #f2f3f8;
  --card-color: #464457;
  --nav-background: #20336b;
  --btn-background-color: #20336b;
  --btn-background-color-hover: #00baf2;
  --btn-color: #fff;
  --btn-color-hover: #20336b;
}

[data-theme="dark"] {
  --body-background: #000;
  --text-primary: #fff;
  --card-background: #222327;
  --card-border: #ccc;
  --card-color: #fff;
  --card-header-color-rgb: #dadce0;
  --nav-background: #464446;
  --btn-background-color: #00baf2;
  --btn-background-color-hover: #20336b;
  --btn-color-hover: #fff;
}

* {
  transition: all 0.3s ease-in-out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.card-footer {
  padding: 8px 16px 16px 16px;
  text-align: center;
  background-color: var(--card-background);
  border-top: solid 2px var(--card-border);
  font-size: 16px;
  color: #464457;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0px 10px 0px 10px;
}

.card-header > span {
  font-size: 16px;
  padding: 6px;
  display: inline-block;
  font-weight: bold;
}

.card {
  margin: 10px 10px 10px 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid var(--card-border);
  background-color: var(--card-background);
  color: var(--card-color);
}

.card-header {
  background-color: var(--card-header-color-rgb);
  color: #333;
}


label {
  font-weight: bold;
  /* display: flex;
  justify-content: flex-end; */
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000a8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  backdrop-filter: blur(5px);
  z-index: 9;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 4px solid;
  border-color: #2ac5f4 #2ac5f4 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid;
  border-color: transparent transparent #20336b #20336b;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 70px;
  height: 70px;
  border-color: #2ac5f4 #2ac5f4 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}